import React from "react"
import {
  Grid,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core"
//COMPONENTS
import BulletItem from "../components/bullet-item"
import Article from "../components/article"
import Carousel from "../components/carousel"
import NumericList from "../components/numeric-list"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Testimonial from "../components/testimonial"
import ListCard from "../components/list-card"
import About from "../components/about"
import Card from "../components/card"
//IMAGES
import AboutImage from "./assets/exnd-GNMHQOe0yRs-unsplash-1365x2048.jpg"
import HeroImage from "./assets/POTENCIAL_20200315_171641-2048x969.jpg"
import IvoneImage from "./assets/pexels-andre-furtado-5180962.jpg"
import ImageEstrategia from "./assets/pexels-pixabay-277124-2.jpg"
import ImageSensibilidad from "./assets/pexels-nataliya-vaitkevich-5201527-2.jpg"
import ImageAccion from "./assets/pexels-andre-furtado-5180962.jpg"
import ImageEquipo from "./assets/pexels-andrea-piacquadio-3830752.jpg"
import ImageTurkisWay from "./assets/jailam-rashad-hSFgNXOztLU-unsplash.jpg"
import ImageSesiones from "./assets/Sesiones-pexels-mentatdgt-1311518.jpg"
import ImageConfianza from "./assets/CONFIDENCE-pexels-moose-photos-1036622.jpg"
import ImageConfidenceRev from "./assets/ConfidenceRevolution.jpg"
import ImageOceanBlue from "./assets/pexels-magda-ehlers-636353.jpg"

//ICONS
import { BsFillChatSquareQuoteFill } from "react-icons/bs"
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"
import { TiChevronRightOutline } from "react-icons/Ti"
import Footer from "../components/footer"
// this is how you use the icon directly: listItemIcon={<iconName/>}
// or you declare the name you want to use: const myIconName = <TiChevronRightOutline size="20px"/>
// and then use it in the code under the "export" section: const icon={myIconName}

export default function IT() {
  const numericListText = [
    "numericListText1",
    "numericListText1",
    "numericListText1",
    "numericListText1",
  ]
  const textList = ["textList1", "textList1", "textList1"]
  const textList2 = ["xxx", "zzz"]
  const icon = <BsFillChatSquareQuoteFill size="40px" />
  const bulletIcon1 = <IoIosArrowForward size="36px" />
  const bulletIcon2 = <IoIosArrowForward size="20px" />
  return (
    <>
      <HeroSection
        bgImage={HeroImage}
        overlay="full"
        h1="Landing Page Name"
        id="Landing Page Name"
        titleClassName="white"
      ></HeroSection>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="bold blue1 font-size-verylarge lineheight-large"
          title="Conozco muy bien lo qué sientes porque yo también he estado ahí"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Te sientes insatisfecha con tu vida por que crees que estas
                desperdiciando tus capacidades y las habilidades que has
                desarrollado en tantos años de experiencia acumulada.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Estas paralizada por las dudas de no saber qué hacer, de no
                saber por dónde empezar, ¿Seré lo suficientemente buena?, ¿Sabré
                lo suficiente?. "No estoy lista todavía".
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Todas estas dudas te causan ansiedad que va en aumento.
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Y al mismo tiempo sientes la intensa necesidad de poder vencer
                ese miedo y esas dudas para poder contribuir al mundo con tu
                conocimiento, creatividad y experiencia.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sientes que tienes mucho que ofrecer a los demás, pero no sabes
                por dónde empezar. ¡Te sientes super atorada!
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Por momentos sientes un impulso y comienzas a trabajar en una
                idea, pero después de unos días pierdes la motivación y no sabes
                por dónde se fue.
              </BulletItem>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="beige1">
        <Article
          layout="wide"
          titleClassName="blue1 tenor font-size-verylarge  lineheight-large "
          title="DESCUBRE CÓMO PODEMOS TRABAJAR JUNTAS"
        >
          <Grid container spacing={3} justify="center">
            <Grid item md={4} xs={10}>
              <Card
                avatar={ImageSesiones}
                titleClassName="tenor font-size-medium"
                className="center"
                title={
                  <span>
                    Power Coaching
                    <br />
                    <strong>Sesiones 1 to 1</strong>
                  </span>
                }
              >
                Nos enfocamos en tus desafíos personales y en como superarlos.
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
              <Card
                avatar={ImageConfianza}
                titleClassName="tenor font-size-medium"
                className="center"
                title={
                  <span>
                    Curso
                    <br />
                    <strong>Amplifica tu Confianza</strong>
                  </span>
                }
              >
                Descubre como, tener confianza en ti misma, puede transformar
                radicalmente tu vida.
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
              <Card
                avatar={ImageConfidenceRev}
                titleClassName="tenor font-size-medium"
                title={
                  <span>
                    Programa Premium
                    <br />
                    <strong>The Confidence Revolution</strong>
                  </span>
                } // "Programa Premium Personalizado 'The Confidence Revolution' "
                className="center"
              >
                Experimenta el poder que llevas dentro y úsalo para conquistar
                tus sueños.
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      {/* IMAGE CARDS */}
      <Section
        color="blue"
        bgImage={ImageOceanBlue}
        overlay="full"
        overlayColor="rgba(98, 128, 174,.2)"
      >
        <Article className="center">
          <span className="playfair  white center font-size-verylarge  lineheight-extralarge">
            ¿Quieres saber cómo?
          </span>
          <br /> <br />
          <span className="center white font-size-medium">
            {" "}
            Mi método combina{" "}
          </span>
          <span className="bold white font-size-medium">
            {" "}
            sensibilidad, estrategia y acción.{" "}
          </span>
          <br /> <br />
          <br /> <br />
        </Article>
        <Article>
          <Grid container spacing={3} justify="center">
            <Grid item md={3} sm={6} xs={10}>
              {/*  <Card icon={icon} title="Sensibilidad" className="center">
              Aprende a interpretar tus emociones para que sepas lo que necesitas hacer en cada momento.
              </Card> */}
              <Card
                avatar={ImageSensibilidad}
                title="Sensibilidad"
                className="center"
              >
                Aprende a interpretar tus emociones para que sepas lo que
                necesitas hacer en cada momento.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={<FaChess size="36px" />}title="Estrategia" className="center">
              Aprende a reajustar tus acciones conscientemente para que te lleven más cerca de tus metas.
              </Card> */}
              <Card
                avatar={ImageEstrategia}
                title="Estrategia"
                className="center"
              >
                Aprende a reajustar tus acciones conscientemente para que te
                lleven más cerca de tus metas.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Acción" className="center"> */}
              <Card avatar={ImageAccion} title="Acción" className="center">
                Toma acción con intención y empieza a ver y disfrutar de
                resultados tangibles rápidamente.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Somos un equipo" className="center"> */}
              <Card
                avatar={ImageEquipo}
                title="Somos un equipo"
                className="center"
              >
                Soy tu confidente y la voz de apoyo que necesitas para surfear
                los desafíos que se te presentan.
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="white">
        <Article className="narrow playfair bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          Te entiendo muy bien.
          <br />
          <br />
        </Article>
        <Article className="center font-size-medium lineheight-verylarge">
          Todo eso que sientes es un llamado a un propósito mayor,
          <br />
          <br />
        </Article>
        <Article className="narrow playfair bold center font-size-verylarge blue1 lineheight-verylarge">
          ¡Estas muy cerca de lograrlo, por eso estas aquí!
        </Article>
      </Section>

      <Section color="turkis-blue1">
        <Article layout="narrow" ClassName="font-size-medium">
          <ListCard
            listItemIcon={<TiChevronRightOutline />}
            className="bold center font-size-medium lineheight-large"
            title="Con mis sesiones y programas aprenderás herramientas que te serán útiles de por vida con las que:"
            titleClassName="bold center font-size-large blue1 lineheight-large"
            text={[
              "Vencerás tus dudas",
              "Amplificarás tu confianza",
              "Lograrás lo que te propones",
            ]}
          />
        </Article>
      </Section>

      <Section color="white" id="sobre mi">
        <Article layout="wide">
          <About
            titleClassName="tenor bold blue1 font-size-verylarge  left lineheight-large"
            title="Soy Ivone Herrera"
            image={AboutImage}
          >
            <span className="turkis-blue1 font-size-medium lineheight-large">
              Mi método combina
            </span>
            <br />
            <span className="bold turkis-blue1 font-size-large lineheight-verylarge">
              Sensibilidad, Estrategia y Acción
            </span>
            <br />
            <br />
            <span className=" blue1  font-size-medium ">
              Soy Mentora, Coach de Confianza, Coach de Emociones y de
              Renovación de Creencias.
              <br />
              <br />
              Entrenadora en Psicología Positiva y Programación Neurolingüística
              y también Ingeniera Física, con Maestría en Simulaciones
              Computacionales.
            </span>
            <br />
            <br />
            <br />
            <span className="bold turkis-blue1 font-size-large lineheight-large">
              Soy una creyente absoluta en el talento y la capacidad femeninas.
            </span>
          </About>
        </Article>
      </Section>

      <Section color="white" id="sobre mi">
        <Article layout="wide">
          <About
            className="transparent"
            titleClassName="tenor bold blue1 font-size-verylarge  left lineheight-large"
            title="Soy Ivone Herrera"
            image={IvoneImage}
          >
            <span className="turkis-blue1 font-size-medium lineheight-large">
              Mi método combina
            </span>
            <br />
            <span className="bold turkis-blue1 font-size-large lineheight-verylarge">
              Sensibilidad, Estrategia y Acción
            </span>
            <br />
            <br />
            <span className=" blue1  font-size-medium ">
              Soy Mentora, Coach de Confianza, Coach de Emociones y de
              Renovación de Creencias.
              <br />
              <br />
              Entrenadora en Psicología Positiva y Programación Neurolingüística
              y también Ingeniera Física, con Maestría en Simulaciones
              Computacionales.
            </span>
            <br />
            <br />
            <br />
            <span className="bold turkis-blue1 font-size-large lineheight-large">
              Soy una creyente absoluta en el talento y la capacidad femeninas.
            </span>
          </About>
        </Article>
      </Section>

      <Section
        className="playfair font-size-verylarge"
        color="turkis-blue1"
        bgImage={ImageTurkisWay}
        overlay="full"
        overlayColor="rgba(250, 246, 246,.4)"
      >
        <Article
          layout="narrow"
          title="Por eso hoy, con mis programas de acompañamiento personalizado"
          titleClassName="playfair white font-size-verylarge lineheight-large"
        >
          <Card>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon1}>
                  Te llevo paso a paso para que salgas de la insatisfacción y
                  las incertidumbre y{" "}
                  <span className="bold">
                    {" "}
                    potencies la confianza y la seguridad en ti misma.{" "}
                  </span>
                </BulletItem>
                <BulletItem icon={bulletIcon1}>
                  Te ayudo a construir{" "}
                  <span className="bold"> la estrategia </span> que necesitas
                  para{" "}
                  <span className="bold">
                    descubrir y conseguir lo que TÚ realmente quieres.{" "}
                  </span>
                </BulletItem>
              </Grid>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon1}>
                  Te enseño{" "}
                  <span className="bold"> herramientas para toda la vida </span>{" "}
                  con las que podrás vencer las dudas, el miedo y la inseguridad
                  que aparecerán en tu camino.
                </BulletItem>
                <BulletItem icon={bulletIcon1}>
                  Y también te <span className="bold"> acompaño y apoyo</span>{" "}
                  durante el trayecto hasta que te sientas segura y confiada
                  para lograr todo lo que te propongas.
                </BulletItem>
              </Grid>
            </Grid>
          </Card>
        </Article>
      </Section>

      <Section color="turkis-blue1" id="Centered-text">
        <Article>Centered text</Article>
      </Section>
      <Section color="turkis-light" id="Numeric-list">
        <Article layout="narrow">
          <NumericList text={numericListText} />
        </Article>
      </Section>

      <Section color="beige1" id="Testimonial1">
        <Article layout="narrow">
          <Carousel dots arrows slidesToShow={1} slidesToScroll={1} fade>
            <Testimonial name="Folan">
              Testimonial # 1 dolores et ea rebum. Stet clita kasd gubergren, no
              sea takimata sanctus est Lorem ipsum dolor sit amet.
            </Testimonial>
            <Testimonial name="Folan">
              Testimonial # 1 clita kasd gubergren, no sea takimata sanctus est
              Lorem ipsum dolor sit amet.
            </Testimonial>
            <Testimonial name="Folan">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet.
            </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section color="beige1" id="Testimonial#2">
        <Article layout="wide">
          <Carousel dots arrows slidesToShow={2} slidesToScroll={1}>
            <Testimonial name="Folan" variation="small">
              Testimonial # 2 Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
              magna aliquyam erat, sed diam voluptua.
            </Testimonial>
            <Testimonial name="Folan" variation="small">
              Testimonial # 2 Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
              magna aliquyam erat, sed diam voluptua.
            </Testimonial>
            <Testimonial name="Folan" variation="small">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Testimonial>
            <Testimonial name="Folan" variation="small">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section color="beige1" id="Bullet-cards">
        <Article layout="narrow">
          <ListCard title="Bullet-cards" text={textList} image={IvoneImage} />
          <ListCard title="Bullet-cards" text={textList} image={IvoneImage} />
          <ListCard title="Bullet-cards" text={textList2} image={IvoneImage} />
        </Article>
      </Section>

      <Section color="beige1" id="sobre mi">
        <Article layout="wide">
          <About title="Sobre mi" image={AboutImage}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet.
          </About>
        </Article>
      </Section>

      <Section color="beige1">
        <Article layout="wide" title="Some title">
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </BulletItem>
            </Grid>
          </Grid>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="wide" title="Some title">
          <Grid container spacing={3} justify="center">
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card icon={icon} title="Title1" className="center">
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
                <BulletItem icon={bulletIcon2}>
                  Lorem ipsum dolor sit amet.
                </BulletItem>
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="narrow" title="Some title">
          <Card>
            <BulletItem icon={bulletIcon1}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </BulletItem>
          </Card>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="narrow" title="FAQ">
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
        </Article>
      </Section>
      <Section color="beige1">
        <Article layout="narrow" title="Testimonial #3">
          <Testimonial name="Folan" variation="small" image={IvoneImage}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Testimonial>
          <Testimonial name="Folan" variation="small" image={IvoneImage}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Testimonial>
          <Testimonial name="Folan" variation="small" image={IvoneImage}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Testimonial>
        </Article>
      </Section>
      <Footer />
    </>
  )
}
